const permissionsData = localStorage.getItem('permissions')
const permissions = (permissionsData == null) ? [] : JSON.parse(permissionsData);
export const state = { permissions };

export const getters = {
    // Whether the user is currently logged in.
    permissions(state) {
        return state.permissions
    }
}

export const actions = {
};

export const mutations = {
};

