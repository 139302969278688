const dates = {
    dateShort(date) {
        return date.toLocaleDateString()
    },
    datetimeShort(date) {
        return this.dateShort(date) + ' ' + this.timeShort(date)
    },
    timeShort(date) {
        return date.toLocaleTimeString()
    },
    toString(date, app) {
        const now = Date.now()
        const timeDate = date.getTime()
        let diff = Math.floor((now - timeDate) / 1000)
        if (diff > 0) {
            if(diff < 60) {
                return app.$t('t-now')
            }
            diff = Math.floor(diff / 60)
            if (diff < 60) {
                return diff + ' ' + app.$t('t-minutes-ago')
            }
            diff = Math.floor(diff / 60)
            if (diff < 24) {
                return diff + ' ' + app.$t('t-hours-ago')
            }
            diff = Math.floor(diff / 24)
            if (diff < 30) {
                return diff + ' ' + app.$t('t-days-ago')
            }
            diff = Math.floor(diff / 30)
            if (diff < 2) {
                return diff + ' ' + app.$t('t-months-ago')
            }
            return this.dateShort(date)
        }

        return this.datetimeShort(date)
    }
}


export default {
    install: (app /*, options */) => {
        dates.app = app
        app.config.globalProperties.$dates = dates
    }
};