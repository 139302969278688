import { authHeader } from './auth-header';
import { baseUrl, baseUrlBack } from '../../backendUtils.js';

export const userService = {
    login,
    logout,
    getAll,
    // register,
};

function login(username, password) {
    let body = new FormData()
    body.append('username', username)
    body.append('password', password)
    const requestOptions = {
        method: 'POST',
        // headers: { 'Content-Type': 'form/data' },
        body: body
    };

    return fetch(baseUrlBack('users/authenticate'), requestOptions)
        .then(handleResponse)
        .then(({data}) => {
            // login successful if there's a jwt token in the response
            if (data.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(data));
                localStorage.setItem('userid', data.id);
                localStorage.setItem('jwt', data.token);
            }
            return data;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('menu');
    localStorage.removeItem('permissions');
}
/*
function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(baseUrlBack(`users/register`), requestOptions).then(handleResponse);
} */

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(baseUrlBack(`users`), requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
                location = baseUrl('/login')
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject({text: error, status: response.status});
        }
        return data;
    });
}
