import appConfig from "../app.config";
import { authHeader } from "./helpers/authservice/auth-header.js";

const productionTip = false && appConfig.mode == 'PROD'
const domainCurrent = window.location.href.replace(/^.*:\/\/([^/]+).*$/, '$1');
const domainTeste   = 'teste.nitroweb.com.br'
const prefixBackend = '/api/'

export const baseUrl = function (path = '/') {
    if (productionTip) {
        return '/administrador' + path
    }
    return path
}
export const baseUrlFront = function (path = '') {
    if (productionTip) {
        return 'https://' + domainCurrent + '/administrador/' + path
    }
    return 'https://localhost:8080/' + path
}
export const baseUrlBack = function (path = '') {
    if (productionTip) {
        return 'https://' + domainCurrent + '/administrador' + prefixBackend + path
    }
    return 'https://' + domainTeste + prefixBackend + path
}

// HTTP
export const http = {
    handleResponseDefault: (response) => {
        const contentType = response.headers.get('Content-type')
        return response.text().then(text => {
            let data = text
            if (String(contentType).search('application/json') > -1) {
              data = JSON.parse(data);
            }
            if (!response.ok || !data.status) {
                if (response.status === 401) {
                    // window.location = '/login';
                }
                const error = (data && data.message) || response.statusText;
                const dataResponse = (data && data.data) || null;
                return Promise.reject({text: error, status: response.status, data: dataResponse});
            }

            return data;
        });
    },
    handleResponseSimple: (response) => {
        const contentType = response.headers.get('Content-type')
        return response.text().then(text => {
            if (!response.ok) {
                let data = text
                if (String(contentType).search('application/json') > -1) {
                    data = JSON.parse(data);
                }
                const error = (data && data.message) || response.statusText;
                const dataResponse = (data && data.data) || null;
                return Promise.reject({text: error, status: response.status, data: dataResponse});
            }
            return response
        })
    },
    paramsToSearchParams: (params) => {
        const searchParams = new URLSearchParams()
        for (const name in params) {
            if (Object.hasOwnProperty.call(params, name)) {
                const element = params[name];
                if (typeof element == 'object' && element != null) {
                    for (const value of element) {
                        searchParams.append(name+'[]', value)
                    }
                } else {
                    let value = element == null ? '' : element
                    searchParams.append(name, value)
                }
            }
        }

        return searchParams.toString()
    },
    get: (uri) => {
        return http.fetch(uri)
    },
    postByArray: (uri, data, headers = {}, bodyIsJson = false) => {
        if (bodyIsJson) {
            return http.post(uri, data, headers, bodyIsJson)
        }
        
        let body = new FormData()
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                body.append(key, data[key])
            }
        }

        return http.post(uri, body, headers, bodyIsJson)
    },
    post: (uri, body, headers = {}, bodyIsJson = false) => {
        let init = {
            method: 'POST',
            body: body,
            headers: headers
        }

        if (bodyIsJson) {
            init.headers['Accept'] = 'application/json';
            init.headers['Content-Type'] = 'application/json';
            init.body = JSON.stringify(body)
        }

        return http.fetch(uri, init)
    },
    put: (uri, body, headers = {}) => {
        let init = {
            method: 'PUT',
            body: body,
            headers: headers
        }

        return http.fetch(uri, init)
    },
    delete: (uri, headers = {}) => {
        let init = {
            // method: 'DELETE',
            headers: headers
        }

        return http.fetch(uri, init, http.handleResponseSimple)
    },
    fetch: (input, init = {}, handleResponse = null) => {

        if (input.substring(0, 4) != 'http') {
            input = baseUrlBack(input)
        }

        init = Object.assign({
            method: 'GET',
            headers: {}
        }, init)

        init.headers = Object.assign(authHeader(), init.headers)

        return fetch(input, init).then(handleResponse == null ? http.handleResponseDefault : handleResponse)
    }
}

export default {
    install: (app) => {
        app.config.globalProperties.$backend      = http
        app.config.globalProperties.$baseUrl = baseUrl
        app.config.globalProperties.$baseUrlFront = baseUrlFront
        app.config.globalProperties.$baseUrlBack  = baseUrlBack
    },
    baseUrlFront,
    baseUrlBack,
    baseUrl,
    http
};