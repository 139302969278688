const filters = {
    sizeHuman (bytes) {
        const scale = [
          'B',
          'KB',
          'MB',
          'GB',
          'TB'
        ]
        let index = 0
  
        while(bytes > 999 && index < 4) {
          bytes = bytes / 1024
          index++
        }

        return Number(bytes).toFixed(1).toLocaleString() + scale[index]
    },
    numberAbrevHuman (v) {
        const scale = [
            '',
            'k',
            'm',
            'mi',
            'b'
          ]
          let index = 0

          while(v > 999 && index < 4) {
            v = v / 1000
            index++
          }
        return Number(v).toFixed(0) + scale[index]
    },
    datetime (datetime) {
        let d = null
        if (typeof datetime == 'number') {
            d = new Date()
            d.setTime(datetime)
        } else if (typeof datetime == 'string') {
            d = new Date(datetime)
        } else if(typeof datetime == 'object' && datetime.constructor.name == 'Date') {
            d = datetime
        }

        return d.toLocaleString()

    }
}


export default {
    install: (app) => {
        app.config.globalProperties.$filters = filters
    }
};