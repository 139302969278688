const menuData = localStorage.getItem('menu')
const menu = (menuData == null) ? [] : JSON.parse(menuData);
export const state = { menu };

export const getters = {
    // Whether the user is currently logged in.
    menu(state) {
        return state.menu
    }
}

export const actions = {
};

export const mutations = {
};

