import store from "@/state/store";
// import { baseUrl } from '../backendUtils.js';

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/auth/register.vue"),
    meta: {
      title: "Registrar",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/auth/forgot-password.vue"),
    meta: {
      title: "Esqueci minha senha",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/index.vue"),
  },
  {
    path: "/account",
    name: "account",
    meta: {
      title: "Conta",
      authRequired: true,
    },
    component: () => import("../views/account/index.vue"),
  },
  {
    path: "/content",
    name: "content",
    meta: {
      title: "content",
      authRequired: true,
    },
    component: () => import("../views/content/index.vue"),
  },
  {
    path: "/content/pages",
    name: "pages",
    meta: {
      title: "Páginas",
      authRequired: true,
    },
    component: () => import("../views/content/pages/index.vue"),
  },
  {
    path: "/content/page/create",
    name: "page_create",
    meta: {
      title: "Criar Página",
      authRequired: true,
    },
    component: () => import("../views/content/pages/page-edit.vue"),
  },
  {
    path: "/content/page/edit/:id",
    name: "page_edit",
    meta: {
      title: "Editar Página",
      authRequired: true,
    },
    component: () => import("../views/content/pages/page-edit.vue"),
  },
  // Products
  {
    path: "/products",
    name: "products",
    meta: {
      title: "Products",
      authRequired: true,
    },
    component: () => import("../views/products/index.vue"),
  },
  {
    path: "/products/create",
    name: "products_create",
    meta: {
      title: "Create Product",
      authRequired: true,
    },
    component: () => import("../views/products/product-edit.vue"),
  },
  {
    path: "/products/edit/:id",
    name: "products_edit",
    meta: {
      title: "Edit Product",
      authRequired: true,
    },
    component: () => import("../views/products/product-edit.vue"),
  },
  // Categories
  {
    path: "/categories",
    name: "category",
    meta: {
      title: "Categories",
      authRequired: true,
    },
    component: () => import("../views/categories/index.vue"),
  },
  {
    path: "/categories/create",
    name: "categories_create",
    meta: {
      title: "Create Category",
      authRequired: true,
    },
    component: () => import("../views/categories/category-edit.vue"),
  },
  {
    path: "/categories/edit/:id",
    name: "categories_edit",
    meta: {
      title: "Edit Category",
      authRequired: true,
    },
    component: () => import("../views/categories/category-edit.vue"),
  },
  // Brands
  {
    path: "/brands",
    name: "brands",
    meta: {
      title: "Brands",
      authRequired: true,
    },
    component: () => import("../views/brands/index.vue"),
  },
  {
    path: "/brands/create",
    name: "brands_create",
    meta: {
      title: "Create Brand",
      authRequired: true,
    },
    component: () => import("../views/brands/brands-edit.vue"),
  },
  {
    path: "/brands/edit/:id",
    name: "brands_edit",
    meta: {
      title: "Edit Brand",
      authRequired: true,
    },
    component: () => import("../views/brands/brands-edit.vue"),
  },
  // contacts
  {
    path: "/contacts",
    name: "contacts",
    meta: {
      title: "Contatos",
      authRequired: true,
    },
    component: () => import("../views/contacts/index.vue"),
  },
  {
    path: "/contacts/create",
    name: "contacts_create",
    meta: {
      title: "Criar Contatos",
      authRequired: true,
    },
    component: () => import("../views/contacts/create.vue"),
  },
  {
    path: "/contacts/details",
    name: "contact_details",
    meta: {
      title: "Detalhes do Contato",
      authRequired: true,
    },
    component: () => import("../views/contacts/details.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout.vue")
  }
];